export default {
  useraddresses: {
    company_data: "company"
  },
  usergroups: {
    channel_data: "channel"
  },
  warehouses: {
    company_data: "company"
  },
  companyAddresses: {
    company_data: "company"
  },
  "confirmation-requests": {
    time_left: "valid_until"
  },
  "inventory-blockings": {
    product_code: "product",
    warehouse_code_data: "warehouse"
  },
  orders: {
    channel_data: "channel"
  },
  emailTemplates: {
    "cms_data.code": "cms_data"
  }
};
