const mapAttributes = ["inventory-blockings", "companyAddresses", "campaigns", "companies", "users"];

export default {
  mapDataForEndpoint(resource, data) {
    if (resource === "communication-gateways") {
      if (typeof data.options === "string" && data.options.length >= 0) {
        data.options = JSON.parse(data.options);
      }
    }
    // transforms { tax_ids: { vat: 'DE123456789' }} to { tax_ids_key_value: [{ key: 'vat', value: 'DE123456789' }] }
    if (resource === "useraddresses" || resource === "companyAddresses") {
      data.tax_ids = {};
      if (data.tax_ids_key_value.length > 0) {
        data.tax_ids_key_value.forEach(taxId => (data.tax_ids[taxId.key] = taxId.value));
      }
      delete data.tax_ids_key_value;
    }
    if (mapAttributes.includes(resource)) {
      data.attributes = {};
      if (data.attributes_key_value.length > 0) {
        data.attributes_key_value.forEach(taxId => (data.attributes[taxId.key] = taxId.value));
      }
      delete data.attributes_key_value;
    }
    if (resource === "shipping/calculations") {
      data = JSON.parse(data.payload);
    }

    return data;
  },
  mapDataForForm(resource, data) {
    // transforms { tax_ids_key_value: [{ key: 'vat', value: 'DE123456789' }] } to { tax_ids: { vat: 'DE123456789' }}
    if (resource === "useraddresses" || resource === "companyAddresses") {
      data.tax_ids_key_value = [];
      if (data.tax_ids) {
        Object.keys(data.tax_ids).forEach(key => data.tax_ids_key_value.push({ key, value: data.tax_ids[key] }));
      }
      delete data.tax_ids;
    }

    if (mapAttributes.includes(resource)) {
      data.attributes_key_value = [];
      if (data.attributes) {
        Object.keys(data.attributes).forEach(key => data.attributes_key_value.push({ key, value: data.attributes[key] }));
      }
      delete data.attributes;
    }
    return data;
  }
};
