<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">{{ icon }}</v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import Field from "@skuhnow/vuetify-admin/src/mixins/field";

export default {
  mixins: [Field],
  computed: {
    icon() {
      if (!this.value.best_result) {
        return "mdi-help";
      }
      const icons = {
        finished: "mdi-check",
        running: "mdi-run",
        pending: "mdi-timer-sand",
        error: "mdi-close-circle"
      };
      return icons[this.value.best_result];
    },
    tooltip() {
      if (!this.value.best_result) {
        return "Unknown";
      }
      return this.$i18n.t(`integrations.batchjobs.stages.status.${this.value.best_result}`);
    }
  }
};
</script>
