<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :sort-by="['code']" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-show>
        <template v-slot:[`item.actions`]="{ item }">
          <va-action-button
            :disabled="item.group !== 'tenant'"
            :label="$t('integrations.company.regions.edit')"
            icon="mdi-pencil"
            text
            exact
            @click="
              $router.push({
                name: `companyRegions_list`,
                query: {
                  filter: JSON.stringify({
                    _companyCode: item.code
                  })
                }
              })
            "
          />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>
<script>
import i18n from "@/i18n";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "../../components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.code"),
          source: "code",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.group"),
          source: "group",
          type: "reference",
          attributes: {
            label: "name",
            reference: "company-groups",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.name"),
          source: "name",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.owner"),
          source: "owner_data",
          type: "reference-array",
          attributes: {
            reference: "companies",
            action: this.$admin.can(["ROLE_ADMIN"]) ? "edit" : null
          }
        },
        {
          label: i18n.t("integrations.fields.regions"),
          source: "regions",
          type: "array-not-empty"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.code"),
          source: "code",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.group"),
          source: "group",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "company-groups",
            searchQuery: "q",
            sortBy: ["name"],
            itemValue: "code"
          }
        },
        {
          label: i18n.t("integrations.fields.name"),
          source: "name",
          resettable: true
        }
      ]
    };
  }
};
</script>
