import filterDefaults from "@/providers/filterProvider/filterDefaults";
import filterMapping from "@/providers/filterProvider/filterMapping";

export default {
  buildFilter(resource, source, value) {
    let returnFilterConfiguration = {
      source: source,
      operator: filterDefaults.defaultOperator,
      value: value
    };

    if (resource in filterMapping) {
      const filterMappingForResource = filterMapping[resource];

      if (source in filterMappingForResource) {
        returnFilterConfiguration = {
          ...returnFilterConfiguration,
          ...filterMappingForResource[source]
        };
      }
    }

    if (typeof returnFilterConfiguration.value === "function") {
      returnFilterConfiguration.value = returnFilterConfiguration.value(value);
    }

    if (value === "~empty~") {
      returnFilterConfiguration.operator = "!";
      returnFilterConfiguration.value = "*";
    }

    return returnFilterConfiguration;
  },
  mergeFilter(resource, filter, query) {
    if (resource === "orders") {
      if (filter._date_from || filter._date_to) {
        query["order_date"] = buildDateRangeFilter(filter._date_from, filter._date_to);
      }
    }
    if (resource === "budgets") {
      if (filter._expiry_date_from || filter._expiry_date_to) {
        query["expiry_date"] = buildDateRangeFilter(filter._expiry_date_from, filter._expiry_date_to);
      }
      if (filter._created_at_from || filter._created_at_to) {
        query["created_at"] = buildDateRangeFilter(filter._created_at_from, filter._created_at_to);
      }
    }
  }
};

function buildDateRangeFilter(dateFrom, dateTo) {
  let dateFromStr = "";
  let dateToStr = "";
  if (dateFrom) {
    const dateFromObject = new Date(Date.parse(dateFrom));
    dateFromStr = dateFromObject.toISOString();
  }
  if (dateTo) {
    let dateToObject = new Date(Date.parse(dateTo));
    dateTo = new Date(dateToObject.getTime() + 60 * 60 * 24 * 1000 - 1);

    dateToStr = dateTo.toISOString();
  }
  return `${dateFromStr}<${dateToStr}`;
}
