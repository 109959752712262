var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('info-text',{attrs:{"resource":_vm.resource,"crud-type":"list"}})]},proxy:true}])},[_c('va-list',{attrs:{"filters":_vm.filters,"sort-by":['code'],"disable-export":""},scopedSlots:_vm._u([{key:"actions",fn:function(slotProps){return [_c('export-poll-button',{attrs:{"resource":_vm.resource.name,"text":"","options":slotProps.listState.options,"filter":slotProps.currentFilter}})]}}])},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-show":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('va-action-button',{attrs:{"disabled":item.group !== 'tenant',"label":_vm.$t('integrations.company.regions.edit'),"icon":"mdi-pencil","text":"","exact":""},on:{"click":function($event){_vm.$router.push({
              name: "companyRegions_list",
              query: {
                filter: JSON.stringify({
                  _companyCode: item.code
                })
              }
            })}}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }