<template>
  <validation-form :id="id" :item="item">
    <base-material-tabs-card :tabs="tabs">
      <template #campaign_information>
        <v-row>
          <v-col>
            <validation-provider
              :name="$t('integrations.fields.code')"
              :rules="{
                required: true,
                regex: /^[a-zA-Z0-9_-]+$/,
                uniqueValidator: !isEdit ? { resource: 'campaigns', field: 'codeEquals' } : false
              }"
              v-slot="{ validate, errors }"
            >
              <va-text-input
                :readonly="isEdit"
                :autofocus="!isEdit"
                :label="$t('integrations.fields.code') + ' *'"
                :hint="$t('integrations.helper.campaign.code')"
                source="code"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.channel')" rules="required" v-slot="{ validate, errors }">
              <va-autocomplete-input
                :readonly="isEdit"
                :label="$t('integrations.fields.channel') + ' *'"
                source="channel"
                reference="channels_globals_as_defaults"
                item-value="id"
                search-query="q"
                :sort-by="['name']"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.type')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.type') + ' *'"
                source="type"
                :choices="CampaignTypeVariants"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-boolean-input
              :label="$t('integrations.fields.all_day')"
              source="all_day"
              @input="allDayActive = $event"
              :hint="$t('integrations.helper.campaign.all_day')"
              persistent-hint
            >
            </va-boolean-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <validation-provider vid="dateFrom" :name="$t('integrations.fields.date_from')" rules="required" v-slot="{ validate, errors }">
              <component
                :is="allDayActive ? 'va-date-input' : 'va-date-time-input'"
                v-bind="{
                  label: $t('integrations.fields.date_from') + ' *',
                  source: 'date_from',
                  format: 'long',
                  hint: $t(allDayActive ? 'integrations.helper.campaign.date_from' : 'integrations.helper.campaign.date_time_from'),
                  'error-messages': errors
                }"
                @input="validate"
              ></component>
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider :name="$t('integrations.fields.date_to')" rules="required|dateNotBefore:@dateFrom" v-slot="{ validate, errors }">
              <component
                :is="allDayActive ? 'va-date-input' : 'va-date-time-input'"
                v-bind="{
                  label: $t('integrations.fields.date_to') + ' *',
                  source: 'date_to',
                  format: 'long',
                  hint: $t(allDayActive ? 'integrations.helper.campaign.date_until' : 'integrations.helper.campaign.date_time_until'),
                  'error-messages': errors
                }"
                @input="validate"
              ></component>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-text-input :label="$t('integrations.fields.description')" source="description" :hint="$t('integrations.helper.campaign.description')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider :name="$t('integrations.fields.status')" rules="required" v-slot="{ validate, errors }">
              <va-select-input
                :label="$t('integrations.fields.status') + ' *'"
                :hint="$t('integrations.helper.campaign.enabled')"
                source="status"
                :choices="CampaignStatusVariants"
                item-text="name"
                item-value="id"
                @input="validate"
                :error-messages="errors"
              ></va-select-input>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-code-input :label="$t('integrations.fields.constraint')" source="constraint" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <va-array-input :label="$t('integrations.fields.attributes')" :hint="$t('integrations.helper.company_address.attributes')" source="attributes_key_value" v-slot="props">
              <v-row>
                <v-col cols="6">
                  <validation-provider :name="$t('integrations.attributes.key')" rules="required" v-slot="{ validate, errors }">
                    <va-combobox-input
                      :label="$t('integrations.attributes.key') + ' *'"
                      v-bind="props"
                      source="key"
                      :choices="CampaignAttributeKeys"
                      item-text="name"
                      item-value="id"
                      @input="validate"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6"><va-text-input :label="$t('integrations.attributes.value')" v-bind="props" source="value"></va-text-input></v-col>
              </v-row>
            </va-array-input>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <div class="d-flex align-center"><va-save-button /><va-cancel-button class="ml-2" /></div>
      </template>
    </base-material-tabs-card>
  </validation-form>
</template>
<script>
import CampaignTypeVariants from "@/resources/campaigns/CampaignTypeVariants";
import CampaignStatusVariants from "@/resources/campaigns/CampaignStatusVariants";
import CampaignAttributeKeys from "@/resources/campaigns/CampaignAttributeKeys";

export default {
  props: ["id", "title", "item", "isEdit"],
  data() {
    return {
      CampaignTypeVariants: CampaignTypeVariants,
      CampaignStatusVariants: CampaignStatusVariants,
      CampaignAttributeKeys: CampaignAttributeKeys,
      allDayActive: false
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "campaign_information",
          label: this.isEdit ? this.$t("integrations.campaign.title.edit", { code: this.item.code }) : this.$t("integrations.campaign.title.create")
        }
      ];
    }
  }
};
</script>
