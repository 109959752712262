<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" disable-create :sort-by="['order.date']" :sort-desc="[true]" disable-export :default-filter="{ test: 'false' }">
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-clone disable-edit disable-delete disable-select>
        <template v-slot:[`field.id`]="{ value }">
          <v-btn class="ml-2" @click="copyToClipboard(value)" x-small text outlined><v-icon x-small>mdi-content-copy</v-icon></v-btn>
          {{ value }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <link-to-json-button :item="item" />
          <resend-order :item="item" />
          <send-feedback :item="item" />
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import { stateVariants, stateVariantsFilter } from "@/resources/order-fulfillments/OrderFulfillmentStateVariants";
import { typeVariants } from "@/resources/order-fulfillments/OfrTypeVariants";
import { testVariants } from "@/resources/order-fulfillments/OfrTestVariants";
import LinkToJsonButton from "@/components/order-fulfillments/LinkToJsonButton";
import ResendOrder from "@/components/order-fulfillments/ResendOrder";
import SendFeedback from "@/components/order-fulfillments/SendFeedback";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "@/components/export/ExportPollButton";
import Currencies from "@/resources/orders/Currencies";

export default {
  components: { ExportPollButton, InfoText, SendFeedback, ResendOrder, LinkToJsonButton },
  props: ["resource", "title"],
  methods: {
    copyToClipboard(value) {
      this.$copyText(value).then(() => {
        this.$store.commit(`messages/showToast`, {
          color: "success",
          message: `Text copied!`
        });
      });
    }
  },
  data() {
    return {
      fields: [
        {
          label: " ",
          source: "order.test",
          type: "function",
          attributes: {
            callback: value => (value === true ? "<b>Test</b>" : "")
          }
        },
        {
          label: i18n.t("integrations.fields.reference"),
          source: "id"
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "order.channel_data",
          type: "reference",
          permissions: ["ROLE_API_ORDERS"],
          attributes: {
            label: "name",
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.user"),
          source: "order.restrict"
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: typeVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.order_date"),
          source: "order.date",
          type: "date",
          sortable: true
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "state",
          type: "select",
          attributes: {
            choices: stateVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.supplier"),
          source: "fulfillment_config.supplier_data",
          type: "reference",
          permissions: ["ROLE_API_ORDERS"],
          attributes: {
            label: "name",
            reference: "fulfiller_companies",
            action: null
          }
        }
      ],

      filters: [
        {
          label: i18n.t("integrations.fields.currency"),
          source: "currency_code",
          type: "select",
          alwaysOn: true,
          resettable: true,
          attributes: {
            choices: Currencies
          }
        },
        {
          label: i18n.t("integrations.fields.fulfillment_type"),
          source: "fulfillment_type",
          type: "select",
          resettable: true,
          attributes: {
            choices: [
              { value: "inhouse", text: i18n.t("integrations.order_fulfillment.fulfillment_type.inhouse") },
              { value: "dropshipping", text: i18n.t("integrations.order_fulfillment.fulfillment_type.dropshipping") },
              { value: "3pl", text: i18n.t("integrations.order_fulfillment.fulfillment_type.3pl") },
              { value: "collect", text: i18n.t("integrations.order_fulfillment.fulfillment_type.collect") }
            ]
          }
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          permissions: ["ROLE_API_ORDERS"],
          attributes: {
            multiple: true,
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "state",
          type: "select",
          alwaysOn: true,
          attributes: {
            choices: stateVariantsFilter,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.supplier"),
          source: "supplier",
          type: "autocomplete",
          resettable: true,
          alwaysOn: true,
          attributes: {
            reference: "fulfiller_companies",
            searchQuery: "q",
            itemText: record => record.name + " (" + record.group + ")",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.user"),
          source: "restrict",
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: typeVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.test"),
          source: "test",
          type: "select",
          attributes: {
            choices: testVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.campaign"),
          source: "campaign",
          type: "autocomplete",
          resettable: true,
          attributes: {
            reference: "campaigns",
            searchQuery: "q",
            itemText: record => record.description + " (" + record.code + ")",
            itemValue: "code",
            sortBy: ["code"]
          }
        },
        {
          label: i18n.t("integrations.fields.product"),
          source: "product",
          resettable: true
        }
      ]
    };
  }
};
</script>
