<template>
  <v-dialog :value="value" @input="closeDialog" @click:outside="closeDialog" width="900">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(createUpdatePackingSpaces)">
        <v-card>
          <v-card-title v-if="isEdit">
            {{ $t("integrations.warehouse.edit_packing_space.title", { reference: item.reference, warehouseCode: warehouseCode }) }}
          </v-card-title>
          <v-card-title v-else>
            {{ $t("integrations.warehouse.create_packing_space.title", { warehouseCode: warehouseCode }) }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <validation-provider
                    :name="$t('integrations.fields.reference')"
                    :rules="{
                      required: true,
                      regex: /^[0-9A-Za-z\-\_]+$/
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input :disabled="isEdit" :label="$t('integrations.fields.reference') + ' *'" v-model="formData.reference" @input="validate" :error-messages="errors" />
                  </validation-provider>
                </v-col>
                <v-col>
                  <va-text-input :label="$t('integrations.fields.description')" v-model="formData.description" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider :name="$t('integrations.fields.type')" rules="required" v-slot="{ errors }">
                    <v-select :label="$t('integrations.fields.type') + ' *'" v-model="formData.type" filled :items="PackageTypesCreateEdit" :error-messages="errors">
                      <template #item="{ item }">
                        <v-icon> {{ item.icon }} </v-icon>&nbsp;{{ item.text }}
                      </template>
                      <template #selection="{ item }">
                        <v-icon> {{ item.icon }} </v-icon>&nbsp;{{ item.text }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
                <v-col>
                  <va-select-input
                    :label="$t('integrations.fields.measurement_system') + ' *'"
                    v-model="formData.measurement_system"
                    :choices="[
                      { value: 'metric', text: $t('integrations.warehouse.packing_space.measurement_system.metric') },
                      { value: 'imperial', text: $t('integrations.warehouse.packing_space.measurement_system.imperial') }
                    ]"
                  ></va-select-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    vid="outerWidth"
                    :name="$t('integrations.fields.outer_width')"
                    :rules="{
                      min_value: 1,
                      required: true,
                      numeric: true
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.outer_width') + ' *'"
                      v-model="formData.outer_width"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixLength"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    vid="outerHeight"
                    :name="$t('integrations.fields.outer_height')"
                    :rules="{
                      min_value: 1,
                      required: true,
                      numeric: true
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.outer_height') + ' *'"
                      v-model="formData.outer_height"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixLength"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    vid="outerLength"
                    :name="$t('integrations.fields.outer_length')"
                    :rules="{
                      min_value: 1,
                      required: true,
                      numeric: true
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.outer_length') + ' *'"
                      v-model="formData.outer_length"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixLength"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    :name="$t('integrations.fields.empty_weight')"
                    :rules="{
                      min_value: 1,
                      required: true,
                      numeric: true,
                      smallerThanOther: '@maxWeight'
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.empty_weight') + ' *'"
                      v-model="formData.empty_weight"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixWeight"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    vid="maxWeight"
                    :name="$t('integrations.fields.max_weight')"
                    :rules="{
                      min_value: 1,
                      required: true,
                      numeric: true
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.max_weight') + ' *'"
                      v-model="formData.max_weight"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixWeight"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    :name="$t('integrations.fields.inner_width')"
                    :rules="{
                      min_value: 1,
                      numeric: true,
                      smallerThanOtherOrEqual: '@outerWidth'
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.inner_width')"
                      v-model="formData.inner_width"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixLength"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    :name="$t('integrations.fields.inner_height')"
                    :rules="{
                      min_value: 1,
                      numeric: true,
                      smallerThanOtherOrEqual: '@outerHeight'
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.inner_height')"
                      v-model="formData.inner_height"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixLength"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    :name="$t('integrations.fields.inner_length')"
                    :rules="{
                      min_value: 1,
                      numeric: true,
                      smallerThanOtherOrEqual: '@outerLength'
                    }"
                    v-slot="{ validate, errors }"
                  >
                    <va-text-input
                      :label="$t('integrations.fields.inner_length')"
                      v-model="formData.inner_length"
                      @input="validate"
                      :error-messages="errors"
                      :suffix="inputSuffixLength"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <va-text-input multiline :label="$t('integrations.fields.comment')" v-model="formData.comment" />
                </v-col>
              </v-row>
              <v-row style="margin-top: 0">
                <v-col>
                  <va-boolean-input :label="$t('integrations.fields.enabled')" v-model="formData.enabled" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" :disabled="saving" @click="closeDialog">
              <v-icon left>mdi-close-circle</v-icon>
              {{ $t("integrations.btn.cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" :loading="saving">
              <v-icon left>mdi-content-save</v-icon>
              {{ $t("integrations.btn.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>
<script>
import admin from "@/plugins/admin";
import { PackageTypesCreateEdit } from "@/resources/packingSpaces/PackageTypes";

export default {
  props: ["warehouseCode", "item", "value"],
  data() {
    return {
      PackageTypesCreateEdit: PackageTypesCreateEdit,
      saving: false,
      keyValues: [],
      formData: {
        type: "box",
        reference: null,
        description: null,
        measurement_system: "metric",
        outer_width: null,
        outer_height: null,
        outer_length: null,
        empty_weight: null,
        max_weight: null,
        inner_width: null,
        inner_height: null,
        inner_length: null,
        comment: null,
        enabled: true
      }
    };
  },
  watch: {
    value(value) {
      if (value && this.item) {
        this.formData = { ...this.item };
      }
    }
  },
  computed: {
    isEdit() {
      return this.item !== null;
    },
    inputSuffixLength() {
      return this.formData.measurement_system === "metric" ? "mm" : "in";
    },
    inputSuffixWeight() {
      return this.formData.measurement_system === "metric" ? "g" : "oz";
    }
  },
  methods: {
    clearFormData() {
      this.formData.type = "box";
      this.formData.reference = null;
      this.formData.description = null;
      this.formData.measurement_system = "metric";
      this.formData.outer_width = null;
      this.formData.outer_height = null;
      this.formData.outer_length = null;
      this.formData.empty_weight = null;
      this.formData.max_weight = null;
      this.formData.inner_width = null;
      this.formData.inner_height = null;
      this.formData.inner_length = null;
      this.formData.comment = null;
      this.formData.enabled = true;
    },
    closeDialog() {
      this.clearFormData();
      this.$emit("input", false);
    },
    async createUpdatePackingSpaces() {
      const linkBase = `/warehouses/${this.warehouseCode}/packing-spaces`;
      this.saving = true;
      const method = this.item ? "put" : "post";
      const url = this.item ? `${linkBase}/${this.item.reference}` : linkBase;

      admin.http
        .request({ method: method, url: url, data: this.formData })
        .then(() => {
          this.$emit("warehousePackingSpacesCreated");
        })
        .catch(() =>
          this.$store.commit(`messages/showToast`, {
            color: "error",
            message: this.$i18n.t("integrations.warehouse.messages.packing_spaces.error_upsert", { reference: this.formData.reference })
          })
        )
        .finally(() => {
          this.saving = false;
          this.closeDialog();
        });
    }
  }
};
</script>
